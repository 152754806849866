import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux';
import { RootState } from '../../configureStore';
import i18n from '../../i18n';
import { LocationType, RestaurantType } from '../../redux/restaurantSlice';
import { api } from '../../utils/api';


const LocationSelect = ({ onLocationChange, value }: {
    onLocationChange: (location: LocationType) => void,
    value?: string;
}) => {
    const [fetchingLocations, setFetchingLocations] = useState(true);
    const restaurant: RestaurantType = useSelector((state: RootState) => state.restaurant)
    const [locations, setLocations] = useState<LocationType[]>([])

    const getLocations = () => {
        setFetchingLocations(true)
        api.get('locations?_sort=locationOrder:ASC').then(({ data }) => {
            data.forEach((loc: any) => {
                loc.label = loc.city
                loc.value = loc.id
                return loc
            });
            setFetchingLocations(true)
            setLocations(data)
            onLocationChange(data[0])
        }).catch(err => {
            setFetchingLocations(false)
            //  console.log("error getting locations");
        })
    }

    const selectLocation = (location: LocationType) => {
        console.log('selectLocation', location)
        onLocationChange(location)
    }

    useEffect(() => {
        getLocations();
    }, [restaurant])


    if (!locations.length) {
        return <span>{i18n.t('getting-locations')}</span>
    }


    return (
        <select className="form-select" onChange={({ target: {
            value: id
        } }) => {
            console.log("onLocationChange", locations, id)
            const location = locations.find(l => l.id == id);
            console.log('location', location)
            if (location)
                selectLocation(location)
        }} defaultValue={value} aria-label="Default select example">
            <option disabled={true} value={"-1"}>...</option>
            {locations.map((location, index) => {
                return <option
                    key={location.id}
                    value={location.id}>{location.city}</option>
            })}
        </select>
    )
}

export default LocationSelect